import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Title = ({ header, tagline }) => {
  return (
    <>
      <h1 className="title">{header}</h1>
      <p className="tagline">{tagline}</p>
    </>
  );
};

Title.propTypes = {
  header: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired
};

export default Title;
