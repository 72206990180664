import React from "react";
import PropTypes from "prop-types";
import Title from "../title";
import AuctionItem from "./item";
import AuctionItemMobile from "./mobile";
import "./index.scss";

const Auction = ({ auction }) => {
  const { header, tagline, components } = auction;
  return (
    <section className="auction">
      <div className="container">
        <Title header={header} tagline={tagline} />
        <AuctionItem components={components} />
        <AuctionItemMobile components={components} />
      </div>
    </section>
  );
};

Auction.propTypes = {
  auction: PropTypes.object.isRequired,
};

export default Auction;
