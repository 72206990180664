import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PropTypes from "prop-types";
import GalaButton from "../../button/gala";
import "./index.scss";

const HonoreeDescription = ({ paragraph, honoree, header }) => {
  return (
    <div className="honoree__description">
      <div
        className="honoree__bg"
        style={{
          backgroundImage: `url(${honoree.fluid.src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          opacity: "0.5"
        }}
      />
      <div className="honoree__title__paragraph d-flex flex-column justify-content-center align-items-center">
        <h3 className="mb-5">{header}</h3>
        <p
          style={{
            width: "80%",
            lineHeight: "2"
          }}
        >
          {documentToReactComponents(paragraph.json)}
        </p>
        <GalaButton secondButton="Buy Now" />
      </div>
    </div>
  );
};

HonoreeDescription.propTypes = {};

export default HonoreeDescription;
