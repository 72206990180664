import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Auction from "../components/auction";
import Sponsor from "../components/sponsor";
import HonoreeDescription from "../components/hero/description";

const GalaPage = ({ data, uri }) => {
  useEffect(() => {
    if (typeof window !== undefined) {
      if (uri === "/gala") {
        window.location.href = "/";
      }
    }
  });

  const { auctionItems, header, honoree, paragraph } = data.contentfulPageGala;

  return (
    <Layout>
      {/* <SEO title="Gala" />
      <div>
        <HonoreeDescription
          paragraph={paragraph}
          honoree={honoree}
          header={header}
        />
        <Auction auction={auctionItems} />
        <Sponsor />
      </div> */}
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulPageGala {
      id
      header
      paragraph {
        json
      }
      auctionItems {
        header
        tagline
        components {
          excerpt
          image {
            fluid {
              src
            }
          }
          auctionItemName
        }
      }
      honoree {
        fluid {
          src
        }
      }
    }
  }
`;

export default GalaPage;
