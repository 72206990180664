import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Title from "../title";
import "./index.scss";

const Sponsor = () => (
  <StaticQuery
    query={graphql`
      query SponsorQuery {
        contentfulModuleSponsors(
          contentful_id: { eq: "6wyAgmr1NIowPxT4DOblZm" }
        ) {
          components {
            sponsorName
            sponsorUrl
          }
          tagline
          header
        }
      }
    `}
    render={(data) => {
      const { header, tagline, components } = data.contentfulModuleSponsors;
      return (
        <section className="sponsor">
          <div className="container">
            <Title header={header} tagline={tagline} />
            <div className="sponsor__name">
              {components.map((component) => (
                <span>
                  <a
                    href={component.sponsorUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {component.sponsorName}
                  </a>
                </span>
              ))}
            </div>
            <div className="mt-5">
              <Link className="btn btn-outline-success" to="/sponsor">
                Become a sponsor
              </Link>
            </div>
          </div>
        </section>
      );
    }}
  />
);

export default Sponsor;
